<template>
    <div class="2xl:max-w-[100rem] w-full mx-auto flex items-start gap-x-4 py-6 xl:py-10 px-4 lg:px-6">
        <aside class="sticky top-32 2xl:top-44 hidden w-44 shrink-0 xl:flex justify-center">
            <ClientOnly>
                <template v-if="leftBanner">
                    <NuxtLink :to="`/ad/${leftBanner?.id}`" target="_blank" external>
                        <NuxtImg :src="leftBanner?.banner" width="160" height="600" />
                    </NuxtLink>
                </template>
                <template v-else>
                    <NuxtImg src="https://placehold.co/160x600" width="160" height="600" />
                </template>
            </ClientOnly>
        </aside>
        <main class="flex-1 px-4 min-w-0">
            <ClientOnly>
                <div class="w-fit mx-auto lg:hidden pb-6" v-if="topMobileAd">
                    <NuxtLink :to="`/ad/${topMobileAd?.id}`" target="_blank" external>
                        <NuxtImg :src="topMobileAd.banner" class="object-contain" width="320" height="50" />
                    </NuxtLink>
                </div>
            </ClientOnly>
            <div>
                <slot />
            </div>
            <ClientOnly>
                <div class="w-fit mx-auto lg:hidden pt-6" v-if="bottomMobileAd">
                    <NuxtLink :to="`/ad/${bottomMobileAd?.id}`" target="_blank" external>
                        <NuxtImg :src="bottomMobileAd.banner" class="object-contain" width="320" height="50" />
                    </NuxtLink>
                </div>
            </ClientOnly>
        </main>
        <aside class="sticky top-32 2xl:top-44 hidden w-44 shrink-0 xl:flex justify-center">
            <ClientOnly>
                <template v-if="rightBanner">
                    <NuxtLink :to="`/ad/${rightBanner?.id}`" target="_blank" external>
                        <NuxtImg :src="rightBanner?.banner" width="160" height="600" />
                    </NuxtLink>
                </template>
                <template v-else>
                    <NuxtImg src="https://placehold.co/160x600" width="160" height="600" />
                </template>
            </ClientOnly>
        </aside>
    </div>
</template>

<script setup lang="ts">
interface Ad {
    id: number;
    url: string;
    banner: string;
}

const { data: leftBanner, refresh: refreshLeftAd } = await useFetch<Ad>('/api/ad', {
    query: {
        position: 'LEFT_SKYSCRAPER'
    },
    immediate: false
})

const { data: rightBanner, refresh: refreshRightAd } = await useFetch<Ad>('/api/ad', {
    query: {
        position: 'RIGHT_SKYSCRAPER'
    },
    immediate: false
})

const { data: topMobileAd, refresh: refreshTopMobileAd } = await useFetch<Ad>('/api/ad', {
    query: {
        position: 'MOBILE'
    },
    immediate: false
})

const { data: bottomMobileAd, refresh: refreshBottomMobileAd } = await useFetch<Ad>('/api/ad', {
    query: {
        position: 'MOBILE'
    },
    immediate: false
})

const route = useRoute()
const viewport = useViewport()

watch(() => route.path, () => {
    if (viewport.isGreaterOrEquals('lg')) {
        refreshLeftAd()
        refreshRightAd()
    } else {
        refreshTopMobileAd()
        refreshBottomMobileAd()
    }
}, {
    immediate: true
})

// Function to handle window resize
watch(viewport.breakpoint, () => {
    if (viewport.isGreaterOrEquals('lg')) {
        if (!leftBanner.value || !rightBanner.value) {
            refreshLeftAd()
            refreshRightAd()
        }
    } else {
        if (!topMobileAd.value || !bottomMobileAd.value) {
            refreshTopMobileAd()
            refreshBottomMobileAd()
        }
    }
})
</script>